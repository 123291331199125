<template>
  <v-container class="pa-4">
    <v-row dense v-if="contract.situation !== 'Cancelado'">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="text-right">
        <BaseButton
          id="btn-create"
          name="btn-create"
          color="secondary"
          title="Novo cadastro"
          icon="fa-solid fa-plus"
          height="40"
          @click="create"
          v-permission="{
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }"
        />
      </v-col>
    </v-row>

    <BaseTableList title="Listagem de subestipulantes">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.active`]="{ item }">
            <span :class="getColor(item.active ? 'Ativo' : 'Inativo')">
              {{ item.active ? 'Ativo' : 'Inativo' }}
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="editCustomer(item)"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <v-tooltip
              bottom
              v-if="item.active === true && contract.situation !== 'Cancelado'"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  small
                  @click="remove(item)"
                  v-permission="{
                    roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                    operator: '==='
                  }"
                >
                  fa-solid fa-user-large-slash
                </v-icon>
              </template>
              <span>Inativar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          title="Voltar"
          color="primary"
          outlined
          @click="back"
        />
      </v-col>
    </v-row>

    <DialogCustomers
      v-if="dialog"
      :contractId="$route.params.id"
      :customerId="customerId"
      :productType="productType"
      :dialog="dialog"
      :title="dialogTitle"
      :edit="dialogEdit"
      :selectItem="selectItem"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { showMessage, confirmMessage } from '@/helpers/messages';
import { getColor } from '@/helpers/utils';
import { ContractService } from '@/services/api/contracts';

export default {
  components: {
    DialogCustomers: () => import('./dialogs/dialog-customers')
  },

  props: {
    productType: {
      type: String
    },
    customerId: {
      type: String
    }
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    dialog: false,
    dialogTitle: '',
    dialogEdit: false,
    selectItem: {},
    options: {},
    headers: [
      { text: 'Cliente', value: 'customerName' },
      { text: 'Quantidade de vidas', value: 'amountLives' },
      { text: 'Status', value: 'active' },
      { text: '', value: 'action' }
    ]
  }),

  computed: {
    ...mapGetters({
      contract: 'contracts/getContractById'
    })
  },

  methods: {
    getColor,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const contractService = new ContractService();
        const { status, data } = await contractService.getCustomers({
          page,
          take: itemsPerPage,
          contractId: this.$route.params.id
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async remove(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente inativar o subestipulante?'
      );

      if (result) {
        try {
          const params = {
            contractId: this.$route.params.id,
            productType: this.productType,
            ...item
          };
          const contractService = new ContractService();
          const { status } = await contractService.deleteCustomers(params);

          if (status === 200 || status === 204) {
            this.search();
            this.$emit('updateInfo');
            showMessage('success', 'Operação realizada com sucesso');
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    back() {
      this.$emit('back');
    },

    create() {
      this.dialog = true;
      this.dialogTitle = 'Adicionar subestipulante';
      this.dialogEdit = false;
      this.selectItem = {};
    },

    editCustomer(item) {
      this.dialog = true;
      this.dialogTitle = 'Visualizar subestipulante';
      this.dialogEdit = true;
      this.selectItem = Object.assign({}, item);
    },

    closeModal(search) {
      this.dialog = false;
      this.dialogTitle = '';
      this.dialogEdit = false;
      this.selectItem = {};

      if (search) {
        this.search();
        this.$emit('updateInfo');
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
