var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-4"},[(_vm.contract.situation !== 'Cancelado')?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12"}},[_c('BaseButton',{directives:[{name:"permission",rawName:"v-permission",value:({
          roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
          operator: '==='
        }),expression:"{\n          roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n          operator: '==='\n        }"}],attrs:{"id":"btn-create","name":"btn-create","color":"secondary","title":"Novo cadastro","icon":"fa-solid fa-plus","height":"40"},on:{"click":_vm.create}})],1)],1):_vm._e(),_c('BaseTableList',{attrs:{"title":"Listagem de subestipulantes"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.active`,fn:function({ item }){return [_c('span',{class:_vm.getColor(item.active ? 'Ativo' : 'Inativo')},[_vm._v(" "+_vm._s(item.active ? 'Ativo' : 'Inativo')+" ")])]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.editCustomer(item)}}},on),[_vm._v(" fa-solid fa-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]),(item.active === true && _vm.contract.situation !== 'Cancelado')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"permission",rawName:"v-permission",value:({
                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                  operator: '==='
                }),expression:"{\n                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n                  operator: '==='\n                }"}],attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.remove(item)}}},on),[_vm._v(" fa-solid fa-user-large-slash ")])]}}],null,true)},[_c('span',[_vm._v("Inativar")])]):_vm._e()]}}],null,true)})]},proxy:true}])}),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"id":"btn-back","name":"btn-back","title":"Voltar","color":"primary","outlined":""},on:{"click":_vm.back}})],1)],1),(_vm.dialog)?_c('DialogCustomers',{attrs:{"contractId":_vm.$route.params.id,"customerId":_vm.customerId,"productType":_vm.productType,"dialog":_vm.dialog,"title":_vm.dialogTitle,"edit":_vm.dialogEdit,"selectItem":_vm.selectItem},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }